export const alerts =[
    {
        id:1,
        title: "ErrorWallet",
        message: "Couldn't connect to wallet."
    },
    {
        id:2,
        title: "ErrorAccount",
        message: "Failed to fetch accounts."
    },
    {
        id:3,
        title: "ErrorAsset",
        message: "Couldn't fetch asset details."
    },
    {
        id:4,
        title: "RemindWallet",
        message: "Connect your wallet first."
    },
    {
        id:5,
        title: "ErrorAmount",
        message: "Amount should be a valid number."
    },
    {
        id:6,
        title: "ErrorZPF",
        message: "Insufficient ZPF Balance."
    },
    {
        id:7,
        title: "ErrorTile",
        message: "Select tile first!"
    },
    {
        id:8,
        title: "ErrorApprove",
        message: "Approve ZPF first!"
    },
    {
        id:9,
        title: "LockedTile",
        message: "Tile locked."
    },
    {
        id:10,
        title: "LowAzero",
        message: "Insufficient AZERO Balance."
    },
    {
        id:11,
        title: "TxFinalised",
        message: "Token Approval Finalised."
    },
    {
        id:12,
        title: "ZPFError",
        message: "Failed to initialise ZPF Contract."
    },
    {
        id:13,
        title: "ErrorAllowance",
        message: "Failed to read Allowance."
    },
    {
        id:14,
        title: "ErrorApproval",
        message: "Failed to Approve ZPF."
    },
    {
        id:15,
        title: "ErrorBet",
        message: "Failed to place bet. Try again later."
    },
    {
        id:16,
        title: "ErrorWrong",
        message: "Something went wrong. Try again later."
    },
    {
        id:17,
        title: "ErrorSize",
        message: "Exceeded Max Allowed Bet, try something smaller."
    }
]
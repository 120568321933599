export const PRECISION = 1000000000000;
export const RE = /^[0-9]*[.]?[0-9]{0,12}$/;
export const blockchainUrl =  "wss://ws.azero.dev";
// Replace the below address with the address of the contract you deployed 
export const CONTRACT_ADDRESS = "5GXPjxQqYtogV1uoH6CPisQJRUksnLZa9A3oEhguNvpfBszD";
// Replace the below abi with the abi of the contract you deployed
export const abi = {
	"source": {
	  "hash": "0x2f7a8971162969458cfb0b15142934ddaa571672497692bf55ff7602d7614b1a",
	  "language": "ink! 4.2.1",
	  "compiler": "rustc 1.69.0-nightly",
	  "build_info": {
		"build_mode": "Release",
		"cargo_contract_version": "2.2.1",
		"rust_toolchain": "nightly-x86_64-apple-darwin",
		"wasm_opt_settings": {
		  "keep_debug_symbols": false,
		  "optimization_passes": "Z"
		}
	  }
	},
	"contract": {
	  "name": "bet_contract",
	  "version": "1.0.0",
	  "authors": [
		"ZapFi Team <zapfi.org>"
	  ]
	},
	"spec": {
	  "constructors": [
		{
		  "args": [
			{
			  "label": "zpf_contract_account",
			  "type": {
				"displayName": [
				  "AccountId"
				],
				"type": 0
			  }
			},
			{
			  "label": "fee_account",
			  "type": {
				"displayName": [
				  "AccountId"
				],
				"type": 0
			  }
			}
		  ],
		  "default": false,
		  "docs": [],
		  "label": "new",
		  "payable": false,
		  "returnType": {
			"displayName": [
			  "ink_primitives",
			  "ConstructorResult"
			],
			"type": 8
		  },
		  "selector": "0x9bae9d5e"
		}
	  ],
	  "docs": [],
	  "environment": {
		"accountId": {
		  "displayName": [
			"AccountId"
		  ],
		  "type": 0
		},
		"balance": {
		  "displayName": [
			"Balance"
		  ],
		  "type": 3
		},
		"blockNumber": {
		  "displayName": [
			"BlockNumber"
		  ],
		  "type": 18
		},
		"chainExtension": {
		  "displayName": [
			"ChainExtension"
		  ],
		  "type": 19
		},
		"hash": {
		  "displayName": [
			"Hash"
		  ],
		  "type": 16
		},
		"maxEventTopics": 4,
		"timestamp": {
		  "displayName": [
			"Timestamp"
		  ],
		  "type": 17
		}
	  },
	  "events": [
		{
		  "args": [
			{
			  "docs": [],
			  "indexed": true,
			  "label": "win",
			  "type": {
				"displayName": [
				  "bool"
				],
				"type": 7
			  }
			},
			{
			  "docs": [],
			  "indexed": false,
			  "label": "random_number",
			  "type": {
				"displayName": [
				  "u8"
				],
				"type": 2
			  }
			}
		  ],
		  "docs": [],
		  "label": "BetResult"
		},
		{
		  "args": [],
		  "docs": [],
		  "label": "LockBetEvent"
		}
	  ],
	  "lang_error": {
		"displayName": [
		  "ink",
		  "LangError"
		],
		"type": 10
	  },
	  "messages": [
		{
		  "args": [],
		  "default": false,
		  "docs": [],
		  "label": "get_reserve_status",
		  "mutates": false,
		  "payable": false,
		  "returnType": {
			"displayName": [
			  "ink",
			  "MessageResult"
			],
			"type": 11
		  },
		  "selector": "0x8fb43167"
		},
		{
		  "args": [
			{
			  "label": "account",
			  "type": {
				"displayName": [
				  "AccountId"
				],
				"type": 0
			  }
			}
		  ],
		  "default": false,
		  "docs": [],
		  "label": "get_zpf_balance",
		  "mutates": false,
		  "payable": false,
		  "returnType": {
			"displayName": [
			  "ink",
			  "MessageResult"
			],
			"type": 11
		  },
		  "selector": "0xb617191e"
		},
		{
		  "args": [
			{
			  "label": "account",
			  "type": {
				"displayName": [
				  "AccountId"
				],
				"type": 0
			  }
			}
		  ],
		  "default": false,
		  "docs": [],
		  "label": "allowance",
		  "mutates": false,
		  "payable": false,
		  "returnType": {
			"displayName": [
			  "ink",
			  "MessageResult"
			],
			"type": 11
		  },
		  "selector": "0x6a00165e"
		},
		{
		  "args": [
			{
			  "label": "value",
			  "type": {
				"displayName": [
				  "Balance"
				],
				"type": 3
			  }
			}
		  ],
		  "default": false,
		  "docs": [],
		  "label": "adjust_zpf_pool",
		  "mutates": true,
		  "payable": false,
		  "returnType": {
			"displayName": [
			  "ink",
			  "MessageResult"
			],
			"type": 12
		  },
		  "selector": "0xb76b81b5"
		},
		{
		  "args": [],
		  "default": false,
		  "docs": [],
		  "label": "are_bets_locked",
		  "mutates": false,
		  "payable": false,
		  "returnType": {
			"displayName": [
			  "ink",
			  "MessageResult"
			],
			"type": 15
		  },
		  "selector": "0x84ee59c2"
		},
		{
		  "args": [
			{
			  "label": "amount",
			  "type": {
				"displayName": [
				  "Balance"
				],
				"type": 3
			  }
			}
		  ],
		  "default": false,
		  "docs": [],
		  "label": "bet",
		  "mutates": true,
		  "payable": false,
		  "returnType": {
			"displayName": [
			  "ink",
			  "MessageResult"
			],
			"type": 12
		  },
		  "selector": "0x610e57b8"
		},
		{
		  "args": [],
		  "default": false,
		  "docs": [],
		  "label": "settle_bet",
		  "mutates": true,
		  "payable": false,
		  "returnType": {
			"displayName": [
			  "ink",
			  "MessageResult"
			],
			"type": 12
		  },
		  "selector": "0xd80e6251"
		},
		{
		  "args": [],
		  "default": false,
		  "docs": [],
		  "label": "refund_bets",
		  "mutates": true,
		  "payable": false,
		  "returnType": {
			"displayName": [
			  "ink",
			  "MessageResult"
			],
			"type": 12
		  },
		  "selector": "0x4cd8b0ca"
		},
		{
		  "args": [],
		  "default": false,
		  "docs": [],
		  "label": "relay",
		  "mutates": false,
		  "payable": false,
		  "returnType": {
			"displayName": [
			  "ink",
			  "MessageResult"
			],
			"type": 15
		  },
		  "selector": "0xd2cdf8a3"
		},
		{
		  "args": [],
		  "default": false,
		  "docs": [],
		  "label": "lock_bet",
		  "mutates": true,
		  "payable": false,
		  "returnType": {
			"displayName": [
			  "ink",
			  "MessageResult"
			],
			"type": 12
		  },
		  "selector": "0xc53fd039"
		},
		{
		  "args": [],
		  "default": false,
		  "docs": [],
		  "label": "unlock_bet",
		  "mutates": true,
		  "payable": false,
		  "returnType": {
			"displayName": [
			  "ink",
			  "MessageResult"
			],
			"type": 12
		  },
		  "selector": "0xee1cc92f"
		}
	  ]
	},
	"storage": {
	  "root": {
		"layout": {
		  "struct": {
			"fields": [
			  {
				"layout": {
				  "leaf": {
					"key": "0x00000000",
					"ty": 0
				  }
				},
				"name": "manager"
			  },
			  {
				"layout": {
				  "leaf": {
					"key": "0x00000000",
					"ty": 0
				  }
				},
				"name": "zpf_psp22"
			  },
			  {
				"layout": {
				  "leaf": {
					"key": "0x00000000",
					"ty": 3
				  }
				},
				"name": "total_tokens"
			  },
			  {
				"layout": {
				  "leaf": {
					"key": "0x00000000",
					"ty": 4
				  }
				},
				"name": "players"
			  },
			  {
				"layout": {
				  "leaf": {
					"key": "0x00000000",
					"ty": 2
				  }
				},
				"name": "random_number"
			  },
			  {
				"layout": {
				  "leaf": {
					"key": "0x00000000",
					"ty": 0
				  }
				},
				"name": "fee_account"
			  },
			  {
				"layout": {
				  "leaf": {
					"key": "0x00000000",
					"ty": 5
				  }
				},
				"name": "bets"
			  },
			  {
				"layout": {
				  "leaf": {
					"key": "0x00000000",
					"ty": 7
				  }
				},
				"name": "settle_in_progress"
			  }
			],
			"name": "BettingContract"
		  }
		},
		"root_key": "0x00000000"
	  }
	},
	"types": [
	  {
		"id": 0,
		"type": {
		  "def": {
			"composite": {
			  "fields": [
				{
				  "type": 1,
				  "typeName": "[u8; 32]"
				}
			  ]
			}
		  },
		  "path": [
			"ink_primitives",
			"types",
			"AccountId"
		  ]
		}
	  },
	  {
		"id": 1,
		"type": {
		  "def": {
			"array": {
			  "len": 32,
			  "type": 2
			}
		  }
		}
	  },
	  {
		"id": 2,
		"type": {
		  "def": {
			"primitive": "u8"
		  }
		}
	  },
	  {
		"id": 3,
		"type": {
		  "def": {
			"primitive": "u128"
		  }
		}
	  },
	  {
		"id": 4,
		"type": {
		  "def": {
			"sequence": {
			  "type": 0
			}
		  }
		}
	  },
	  {
		"id": 5,
		"type": {
		  "def": {
			"sequence": {
			  "type": 6
			}
		  }
		}
	  },
	  {
		"id": 6,
		"type": {
		  "def": {
			"tuple": [
			  0,
			  3
			]
		  }
		}
	  },
	  {
		"id": 7,
		"type": {
		  "def": {
			"primitive": "bool"
		  }
		}
	  },
	  {
		"id": 8,
		"type": {
		  "def": {
			"variant": {
			  "variants": [
				{
				  "fields": [
					{
					  "type": 9
					}
				  ],
				  "index": 0,
				  "name": "Ok"
				},
				{
				  "fields": [
					{
					  "type": 10
					}
				  ],
				  "index": 1,
				  "name": "Err"
				}
			  ]
			}
		  },
		  "params": [
			{
			  "name": "T",
			  "type": 9
			},
			{
			  "name": "E",
			  "type": 10
			}
		  ],
		  "path": [
			"Result"
		  ]
		}
	  },
	  {
		"id": 9,
		"type": {
		  "def": {
			"tuple": []
		  }
		}
	  },
	  {
		"id": 10,
		"type": {
		  "def": {
			"variant": {
			  "variants": [
				{
				  "index": 1,
				  "name": "CouldNotReadInput"
				}
			  ]
			}
		  },
		  "path": [
			"ink_primitives",
			"LangError"
		  ]
		}
	  },
	  {
		"id": 11,
		"type": {
		  "def": {
			"variant": {
			  "variants": [
				{
				  "fields": [
					{
					  "type": 3
					}
				  ],
				  "index": 0,
				  "name": "Ok"
				},
				{
				  "fields": [
					{
					  "type": 10
					}
				  ],
				  "index": 1,
				  "name": "Err"
				}
			  ]
			}
		  },
		  "params": [
			{
			  "name": "T",
			  "type": 3
			},
			{
			  "name": "E",
			  "type": 10
			}
		  ],
		  "path": [
			"Result"
		  ]
		}
	  },
	  {
		"id": 12,
		"type": {
		  "def": {
			"variant": {
			  "variants": [
				{
				  "fields": [
					{
					  "type": 13
					}
				  ],
				  "index": 0,
				  "name": "Ok"
				},
				{
				  "fields": [
					{
					  "type": 10
					}
				  ],
				  "index": 1,
				  "name": "Err"
				}
			  ]
			}
		  },
		  "params": [
			{
			  "name": "T",
			  "type": 13
			},
			{
			  "name": "E",
			  "type": 10
			}
		  ],
		  "path": [
			"Result"
		  ]
		}
	  },
	  {
		"id": 13,
		"type": {
		  "def": {
			"variant": {
			  "variants": [
				{
				  "fields": [
					{
					  "type": 9
					}
				  ],
				  "index": 0,
				  "name": "Ok"
				},
				{
				  "fields": [
					{
					  "type": 14
					}
				  ],
				  "index": 1,
				  "name": "Err"
				}
			  ]
			}
		  },
		  "params": [
			{
			  "name": "T",
			  "type": 9
			},
			{
			  "name": "E",
			  "type": 14
			}
		  ],
		  "path": [
			"Result"
		  ]
		}
	  },
	  {
		"id": 14,
		"type": {
		  "def": {
			"variant": {
			  "variants": [
				{
				  "index": 0,
				  "name": "PermissionDenied"
				},
				{
				  "index": 1,
				  "name": "MismatchedArguments"
				},
				{
				  "index": 2,
				  "name": "ValueExceedReserve"
				},
				{
				  "index": 3,
				  "name": "ValueExceedMaxBet"
				},
				{
				  "index": 4,
				  "name": "InsufficientBalanceToBet"
				},
				{
				  "index": 5,
				  "name": "TransferFailed"
				},
				{
				  "index": 6,
				  "name": "InsufficientAllowance"
				},
				{
				  "index": 7,
				  "name": "Overflow"
				},
				{
				  "index": 8,
				  "name": "InsufficientReserveBalance"
				},
				{
				  "index": 9,
				  "name": "SettleInProgress"
				},
				{
				  "index": 10,
				  "name": "NoPendingBets"
				},
				{
				  "index": 11,
				  "name": "LockFirst"
				},
				{
				  "index": 12,
				  "name": "NotLocked"
				}
			  ]
			}
		  },
		  "path": [
			"bet_contract",
			"betting_contract",
			"BetError"
		  ]
		}
	  },
	  {
		"id": 15,
		"type": {
		  "def": {
			"variant": {
			  "variants": [
				{
				  "fields": [
					{
					  "type": 7
					}
				  ],
				  "index": 0,
				  "name": "Ok"
				},
				{
				  "fields": [
					{
					  "type": 10
					}
				  ],
				  "index": 1,
				  "name": "Err"
				}
			  ]
			}
		  },
		  "params": [
			{
			  "name": "T",
			  "type": 7
			},
			{
			  "name": "E",
			  "type": 10
			}
		  ],
		  "path": [
			"Result"
		  ]
		}
	  },
	  {
		"id": 16,
		"type": {
		  "def": {
			"composite": {
			  "fields": [
				{
				  "type": 1,
				  "typeName": "[u8; 32]"
				}
			  ]
			}
		  },
		  "path": [
			"ink_primitives",
			"types",
			"Hash"
		  ]
		}
	  },
	  {
		"id": 17,
		"type": {
		  "def": {
			"primitive": "u64"
		  }
		}
	  },
	  {
		"id": 18,
		"type": {
		  "def": {
			"primitive": "u32"
		  }
		}
	  },
	  {
		"id": 19,
		"type": {
		  "def": {
			"variant": {}
		  },
		  "path": [
			"ink_env",
			"types",
			"NoChainExtension"
		  ]
		}
	  }
	],
	"version": "4"
  }
import { useEffect, useState } from "react";
import "../styles.css";
import PlayComponent from "./PlayComponent";
import { PRECISION } from "../constants";
import maintenancelogo from "../icons/maintenance.png"
import abiJson from "../bet_contract.json";
import { Abi } from "@polkadot/api-contract";

export default function ContainerComponent(props) {
  const activeTab = props.selectedTab;
  const setActiveTab = props.setActiveTab;
  const [azeroBalance, setAzeroBalance] = useState('0');
  const [amountOfZPF, setAmountOfZPF] = useState(0);
  const [totalZPF, setTotalZPF] = useState(0);
  const [isLock, setIsLock] = useState(false);
  const [isWin, setIsWin] = useState(false);
  const [isLose, setIsLose] = useState(false);
  const [isServerOnline, setIsServerOnline] = useState(false);


  useEffect(() => {
    getHoldings();
  }, []);

  useEffect(() => {
    fetchServerStatus();
    const intervalId = setInterval(() => {
      fetchServerStatus();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!props.api) {
      return;
    }

    const unsubscribe = props.api.query.system.events((events) => {
      const abi = new Abi(abiJson, props.api.registry.getChainProperties());
      const addr = props.network.address;
      const onlyContractEvents = events.filter((event) => props.api.events.contracts.ContractEmitted.is(event.event));
      for (const { event } of onlyContractEvents) {
        const [contractAddr, eventData] = event.data;
        if (contractAddr.toString() !== addr) continue;

        try {
          const decodedEvent = abi.decodeEvent(eventData);
          if (decodedEvent.event.identifier === 'LockBetEvent') {
            setIsLock(true);
          }

          if (decodedEvent.event.identifier === 'BetResult') {
            const win = decodedEvent.args[0].toJSON();
            setIsLock(false);

            if (win === true) {
              setIsWin(true);
            } else if (win === false) {
              setIsLose(true);
            }
          }
        } catch (err) {
          console.log("Fail to decode", err);
        }
      }
    });
    return () => {
      unsubscribe();
    }
  }, [props.api]);

  async function fetchServerStatus() {
    try {
      const response = await fetch("/server-status");

      if (response.ok) {
        const data = await response.json();

        if (data.status === "online") {
          setIsServerOnline(true);
        } else {
          setIsServerOnline(false);
        }
      } else {
        console.error("Server Status Error:", response.statusText);
        setIsServerOnline(false);
      }
    } catch (error) {
      console.error("Error fetching server status:", error);
      setIsServerOnline(false);
    }
  }


  // Fetch the pool details and personal assets details.
  async function getHoldings() {
    if (props.contract === null || !props?.activeAccount?.address) {
      return;
    }

    const { nonce, data: azeroBalance } = await props.api.query.system.account(props.activeAccount.address);
    let azeroBalance_ = parseFloat(azeroBalance.free.toHuman().replace(/\,/g, '')) / 1e12.toFixed(6);
    setAzeroBalance(azeroBalance_.toLocaleString());

    const azero_value = 0;

    try {

      let callOptions = {
        gasLimit: props.api.createType('WeightV2', {
          refTime: 99999999999,
          proofSize: 99999999999,
        }),
        storageDepositLimit: 99999999999
      };

      callOptions.value = azero_value;


      await props.contract.query
        .getZpfBalance(props.network.address, callOptions, props.activeAccount.address)
        .then((res) => {
          if (res.result.toHuman().Err)
            throw new Error(res.result.toHuman().Err.Module.message);
          else return res.output.toHuman();
        })
        .then((res) => {
          setAmountOfZPF(res.Ok.replace(/,/g, "") / PRECISION);
        })
        .catch((err) => {
          alert(err);
        });

      await props.contract.query
        .getReserveStatus(props.network.address, callOptions)
        .then((res) => {
          if (res.result.toHuman().Err)
            throw new Error(res.result.toHuman().Err.Module.message);
          else return res.output.toHuman();
        })
        .then((res) => {
          setTotalZPF(res.Ok.replace(/,/g, "") / PRECISION);
        })
        .catch((err) => {
          alert(err);
        });
    } catch (err) {
      console.log("Couldn't Fetch holdings", err);
    }
  }

  return (
    <div className="centerBody">
      <div className="centerContainer">
        {isServerOnline ? (
          activeTab === "Play" && (
            <PlayComponent
              contract={props.contract}
              getHoldings={() => getHoldings()}
              activeAccount={props.activeAccount}
              signer={props.signer}
              setActiveAccount={props.setActiveAccount}
              connect={() => props.connect()}
              holding={{
                amountOfZPF,
                totalZPF,
                azeroBalance,
              }}
              api={props.api}
              network={props.network}
              abi={props.abi}
              isLock={isLock}
              setIsLock={setIsLock}
              isWin={isWin}
              setIsWin={setIsWin}
              isLose={isLose}
              setIsLose={setIsLose}
            />
          )
        ) : (
          <div className="offlinelogo">
            <img src={maintenancelogo} alt="Maintenance Logo" />
          </div>
        )}
      </div>
    </div>
  );
}

export const TILE_STATUSES = {
    HIDDEN: "hidden",
    LOSE: "lose",
    WIN: "win",
    SELECTED: "selected",
}

export function createBoard(boardSize) {
    const board = [];
    for (let x = 0; x < boardSize; x++) {
        const row = []
        for (let y = 0; y < boardSize; y++) {
            row.push({
                x,
                y,
                status: TILE_STATUSES.HIDDEN,
                SELECTED: false,
            });
        }
        board.push(row);
    }
    return board;
}
import { useEffect, useState } from "react";
import { abi, CONTRACT_ADDRESS, blockchainUrl } from "./constants";
import ContainerComponent from "./components/ContainerComponent";
import "./styles.css";
import { ApiPromise, WsProvider } from "@polkadot/api";
import { ContractPromise } from "@polkadot/api-contract";
import { web3FromSource } from "@polkadot/extension-dapp";
import dotlogo from "./icons/doubleortroublelogo.png"
import zaplogo from "./icons/zapfibanner.png"

export default function App() {
  const [myContract, setMyContract] = useState(null);
  const [activeAccount, setActiveAccount] = useState();
  const [signer, setSigner] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Play");
  const [network, setNetwork] = useState({url: blockchainUrl, address: CONTRACT_ADDRESS});
  const [api, setApi] = useState(null);
  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const [showDisclosure, setShoWDisclosure] = useState(true);
  const [hideDisclosure, setHideDisclosure] = useState(false);

  useEffect(() => {
    (async () => {
      activeAccount &&
        setSigner(
          await web3FromSource(activeAccount.meta.source).then(
            (res) => res.signer
          )
        );
    })();

    const disclosureHidden = localStorage.getItem("disclosureHidden");
    if (disclosureHidden === "true") {
      setShoWDisclosure(false);
      setHideDisclosure(true);
    }
  }, [activeAccount, showDisclosure]);

  const handleCheckbox = () => {
    setHideDisclosure((prev) => {
      const newState = !prev;
      localStorage.setItem("disclosureHidden", newState.toString());
      return newState;
    });
    //localStorage.setItem("disclosureHidden", "true");
    //setHideDisclosure(true);
    //setShoWDisclosure(false);
  };

  async function connect(url = network.url, address = network.address) {
    try {
      const wsProvider = new WsProvider( url );
      const api = await ApiPromise.create({ provider: wsProvider });
      setApi(api);
      const contract = new ContractPromise(api, abi, address);
      setMyContract(contract);
      //setSelectedTab("Play");
      setNetwork({
        "url": url,
        "address": address
      })
      

    } catch (err) {
      alert("Network Error: Couldn't connect to wallet." + err);
    }
  }

  return (
    <div className="pageBody">
      <div className="navBar">
        <div className="appName">
          {" "}
          <img src = {dotlogo} className="appnameIcon" />
        </div>
        <div className="howToPlayButton"
           onClick={() => setShowHowToPlay(true)}>How To Play
        </div>
      </div>
      <ContainerComponent
        contract={myContract}
        selectedTab={selectedTab}
        connect={(url, addrs) => connect(url, addrs)}
        activeAccount={activeAccount}
        signer={signer}
        setActiveAccount={(val) => setActiveAccount(val)}
        setActiveTab={(val) => setSelectedTab(val)}
        setNetwork={(val) => setNetwork(val)}
        network={network}
        api = {api}
        abi = {abi}
      />
      {showHowToPlay && (
        <div className="overlay">
          <div className="popup">
            {
              <div className="tabBody">
              <div className="popupHeader">How To Play</div>
              <div className="howtoplaybox">
                Playing Double Or Trouble is simple! Kindly follow these steps:
                <ol className="stepsList">
                <li>Click "Connect" to connect your wallet with Polkadot.js and select your account. </li>
                <li>Click the "Approve" button to allow ZPF on your behalf.</li>
                <li>With a 5 by 5 board, there would be a total of 25 coins. There would be 12 green ZPF coins and 13 red ZPF coins. To win the game and double your bet, you have to correctly guess the green ZPF coin. Picking a red ZPF coin would make you lose your bet amount.</li>
                <li>Select your chosen tile out of the existing 25 tiles. Enter your bet amount and click "Play"!</li>
                <li>When player loses their bet, 90% of the amount would be stored back to the game reserve while 10% would be sent to the Ecosystem wallet where it would be distributed back to the team(3%), ZapFi stakers(5%) and Auspicious Paradigm holders(2%) on a monthly basis. For more information head over <a className="hyperlink" href="https://zapfi.org/ecosystem/" target="_blank" rel="noopener noreferrer">here.</a></li>
                <li>We disclose that Double Or Trouble is intended as an incentive for ZapFi stakers and should only be taken as fun entertainment only. It does not use a VRF Oracle which makes the source of randomness not 100% unpredictable and unbiased.</li>
                <li>We hope you enjoyed what we've built. Populating the Aleph Zero ecosystem has always been our priority. Goodluck and have fun.</li>
              </ol>
              <div className="htpfootnote">
                *With 12 green ZPF and 13 red ZPF coins, ZapFi has a 4% house edge against players. Upon engaging, you have read and agreed to the Disclaimer and <a className="hyperlink" href="https://zapfi.org/terms-of-service/" target="_blank" rel="noopener noreferrer">Terms of Service</a>.
                <div className="version">v1.0.0</div>
                </div>
              </div>
              <div className="popupbutton" onClick={() => setShowHowToPlay(false)}>Close</div>
            </div>
            }
          </div>
        </div>
      )}
      {showDisclosure && (
        <div className="overlay">
          <div className="popup">
            {
              <div className="tabBody">
                <div className="popupHeader">DISCLOSURE</div>
                <div className="disclaimerbox">
                  {"Our goal for Double Or Trouble was to ultimately provide incentives to ZapFi stakers as $ZPF would be distributed for free by staking. Users could also get $ZPF by buying from DEX. By clicking Play, players are 18+ and have agreed on our Diclosure and Disclaimer. Enjoy!"}
                  </div>
                  <div className="checkboxcont">
                  <label>
                  <input
                    type="checkbox"
                    checked={hideDisclosure}
                    onChange={handleCheckbox}
                  />
                 <span className="dsma">Don't show me again</span>
                </label>
                  </div>
                <div className="popupbutton" onClick={() => setShoWDisclosure(false)}>Got it!</div>
              </div>
            }
          </div>
        </div>
      )}
      <div className="footer">
        <div className="footerDisclaimer">
          {"DISCLAIMER: Double Or Trouble is just a game and should be considered as fun and entertainment purpose only. Upon engaging on this platform, you have agreed that you have read How To Play and to not hold ZapFi or any organisations responsible for any losses or damage as you are the only one responsible for your actions. You should be 18+ to engage and note that ZapFi has a 4% house edge."}
        </div>
        <div className="footerRight">
          <a href="https://zapfi.org" target="_blank" rel="noopener norefferer">
          <img src = {zaplogo} className="footerLogo" style={{ width: "auto", height: "30px"}} />
          </a>
        </div>
      </div>
    </div>
  );
}